import './Schedule.scss';
import './InfoPage.scss';

import carriage from './schedule_icons/carriage.png';
import cheers from './schedule_icons/cheers.png';
import croquet from './schedule_icons/croquet.png';
import dance from './schedule_icons/dance.png';
import fireworks from './schedule_icons/fireworks.png';
import flowers from './schedule_icons/flowers.png';
import rings from './schedule_icons/rings.png';
import tray from './schedule_icons/tray.png';

function Schedule() {
  return (
    <div id="Schedule" className="InfoPage">
      <h2>Wedding schedule</h2>
      <ul>
        <li>
          <span className="Cell Icon"><img src={cheers} alt="Cheers" /></span>
          <span className="Cell">2pm</span>
          <span className="Cell">
            Arrival
          </span>
        </li>
        <li>
          <span className="Cell Icon"><img src={rings} alt="Rings" /></span>
          <span className="Cell">2:30pm</span>
          <span className="Cell">
            Ceremony
          </span>
        </li>
        <li>
          <span className="Cell Icon"><img src={croquet} alt="Croquet" /></span>
          <span className="Cell">3pm</span>
          <span className="Cell">
            Drinks, canapes & croquet
          </span>
        </li>
        <li>
          <span className="Cell Icon"><img src={tray} alt="Food" /></span>
          <span className="Cell">5pm</span>
          <span className="Cell">
            Wedding Breakfast
          </span>
        </li>
        <li>
          <span className="Cell Icon"><img src={dance} alt="Dancing" /></span>
          <span className="Cell">7:30pm</span>
          <span className="Cell">
            Cake & dancing
          </span>
        </li>
        <li>
          <span className="Cell Icon"><img src={flowers} alt="Bouquet" /></span>
          <span className="Cell">9pm</span>
          <span className="Cell">
            Evening bites & bouquet throw
          </span>
        </li>
        <li>
          <span className="Cell Icon"><img src={fireworks} alt="Fireworks" /></span>
          <span className="Cell">10pm</span>
          <span className="Cell">
            Sparklers & Fireworks
          </span>
        </li>
        <li>
          <span className="Cell Icon"><img src={carriage} alt="Carriage" /></span>
          <span className="Cell">12pm</span>
          <span className="Cell">
            Carriages
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Schedule;
