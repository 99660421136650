import './ErrorPage.scss';
import './InfoPage.scss';

function GenericError() {
  return (
    <div className="InfoPage ErrorMessage">
      <h2>💔 We&#39;ve encountered an error</h2>
      <p>
        We&#39;re really sorry about this but it seems like we&#39;ve
        encountered an error. Please get in touch with us and we&#39;ll
        be able to help with any queries or problems.
      </p>
    </div>
  );
}

export default GenericError;
