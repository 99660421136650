import React, { useState, useEffect } from 'react';
import {
  useLocation,
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import Home from './Home';
import RSVP from './RSVP';
import Confirmation from './Confirmation';
import WishingWell from './WishingWell';
import Navigation from './Navigation';
import Loading from './Loading';
import PhotoShare from './PhotoShare';

import GenericError from './GenericError';
import ErrorRecordingResponses from './ErrorRecordingResponses';
import PageNotFound from './PageNotFound';
import InvitationNotFound from './InvitationNotFound';

import './index.css';
import Schedule from './Schedule';

Sentry.init({
  dsn: 'https://6b539afedb1165d7fb754f4be15be34b@o357051.ingest.us.sentry.io/4506976607404032',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', '192.168.86.248', /https:\/\/estelle-augustin\.wedding/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

async function fetchInvitiationData(invitationId) {
  const responseObj = await fetch(`/.netlify/functions/fetchInviteDetails?id=${invitationId}`);
  if (!responseObj.ok) {
    Sentry.captureMessage(`Unable to fetch invite details. Status code from server: ${responseObj.status}.`);
    throw new Error('Unable to fetch invite details');
  }
  const responseData = await (responseObj).json();
  return responseData;
}

const wireRouter = (setLoaded) => createBrowserRouter([
  {
    path: '/',
    element: <PageWrapper setLoaded={setLoaded} />,
    children: [
      {
        path: '/*',
        element: <PageNotFound />,
      },
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/schedule',
        element: <Schedule />,
      },
      {
        path: '/rsvp/:id',
        element: <RSVP />,
        errorElement: <InvitationNotFound />,
        loader: ({ params }) => fetchInvitiationData(params.id),
      },
      {
        path: '/confirmation-attending',
        element: <Confirmation
          attending
        />,
      },
      {
        path: '/confirmation-not-attending',
        element: <Confirmation
          attending={false}
        />,
      },
      {
        path: '/error-recording-responses',
        element: <ErrorRecordingResponses />,
      },
      {
        path: '/wishing-well',
        element: <div className="InfoPage"><WishingWell /></div>,
      },
      {
        path: 'photo-share',
        element: <PhotoShare />,
      },
      {
        path: 'photo-share/:key',
        element: <PhotoShare />,
      },
    ],
  },
]);

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elementToScrollTo = document.querySelector(location.hash);
      if (elementToScrollTo) elementToScrollTo.scrollIntoView();
    } else {
      const mainContent = document.querySelector('.MainContent');
      if (mainContent) mainContent.scrollTo(0, 0);
    }
  }, [location.pathname]);
}

function PageWrapper({ setLoaded }) {
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<GenericError />}>
      <ScrollToTop />
      <Outlet />
    </Sentry.ErrorBoundary>
  );
}

PageWrapper.propTypes = {
  setLoaded: PropTypes.func.isRequired,
};

function Root() {
  const [pageLoaded, setLoaded] = useState(false);

  return (
    <React.StrictMode>
      <Navigation />
      <div className="Placeholder" />
      <div className="MainContent">
        { !pageLoaded ? <Loading /> : null }
        <RouterProvider router={wireRouter(setLoaded)} />
      </div>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
