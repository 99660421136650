import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import './ErrorPage.scss';
import './InfoPage.scss';

function PageNotFound() {
  useEffect(() => {
    Sentry.captureMessage('Page was not found.');
  }, []);

  return (
    <div className="InfoPage ErrorMessage">
      <h2>💔 This page was not found</h2>
      <p>
        Unfortunately the page you&#39;re looking for cannot be found.
        You can find the homepage
        {' '}
        <a href="/">here.</a>
        {' '}
        Please contact us if you believe this to be an error.
      </p>
    </div>
  );
}

export default PageNotFound;
