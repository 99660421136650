import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Loading from './Loading';

import './RSVP.scss';

function getBooleanValueFromString(string) {
  return string ? string.toLowerCase() === 'yes' : false;
}

function RSVP() {
  const serverData = useLoaderData();
  const peopleOnInvite = serverData.invitePerson2 ? 2 : 1;
  const { peopleData, acommodationIncluded, allowedAdditinalPeople } = serverData;
  const navigate = useNavigate();
  let confirmationPageUrlSuffix = `?person1=${serverData.invitePerson1}`;
  if (peopleOnInvite === 2) confirmationPageUrlSuffix += `&person2=${serverData.invitePerson2}`;

  if (peopleData.length === 0) {
    peopleData.push({ name: serverData.invitePerson1 });
    if (peopleOnInvite === 2) {
      peopleData.push({ name: serverData.invitePerson2 });
    }
  }

  const [formData, updateData] = useState(serverData.peopleData.map((person) => ({
    namedInvitee: person.name && (person.name === serverData.invitePerson1
    || person.name === serverData.invitePerson2),
    dietaryRequirements: '',
    name: '',
    order: 'beef',
    attending: '',
    ...person,
    isKid: getBooleanValueFromString(person.isKid),
    requiresTransport: getBooleanValueFromString(person.requiresTransport),
  })));

  const namedInviteeAttending = formData.some(
    (person) => person.namedInvitee && getBooleanValueFromString(person.attending),
  );

  const plusOnesAttendingWithNoNamedInvite = !namedInviteeAttending
  && formData.some((person) => getBooleanValueFromString(person.attending));

  if (plusOnesAttendingWithNoNamedInvite) {
    updateData(formData.filter((person) => person.namedInvitee));
  }

  const plusOnesNotAttending = formData.some((person) => !person.namedInvitee
  && !getBooleanValueFromString(person.attending));

  if (plusOnesNotAttending) {
    updateData(formData.filter((person) => person.namedInvitee
    || getBooleanValueFromString(person.attending)));
  }

  const [hasSubmitted, changeSumbitted] = useState(false);
  const [isLoading, setLoading] = useState(false);

  async function submit(event) {
    event.preventDefault();
    changeSumbitted(true);
    setLoading(true);

    const response = await fetch('/.netlify/functions/recordResponses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: serverData.id,
        people: formData,
      }),
    });

    if (response.ok) {
      if (formData.some((person) => getBooleanValueFromString(person.attending))) {
        navigate(`/confirmation-attending${confirmationPageUrlSuffix}`);
      } else {
        navigate('/confirmation-not-attending');
      }
    } else {
      Sentry.captureMessage(`Error recording responses: ${response.status}.`);
      navigate('/error-recording-responses');
    }
  }

  const reachedMaximumNumberOfInvitees = formData.length - peopleOnInvite >= 4;

  function addPerson() {
    if (reachedMaximumNumberOfInvitees) return;
    updateData([...formData, {
      dietaryRequirements: '',
      name: '',
      attending: 'Yes',
      isKid: false,
      requiresTransport: false,
    }]);
  }

  return isLoading ? <Loading /> : (
    <div id="RSVP">
      <div id="HeaderImage" />
      <div id="InviteBody">
        <h2>We would love to see you at our special day!</h2>
        <p>
          Dear
          {' '}
          <span className="PersonName">{serverData.invitePerson1}</span>
          {
            peopleOnInvite === 2
              ? (
                <>
                  {' '}
                  and
                  {' '}
                  <span className="PersonName">{serverData.invitePerson2}</span>
                </>
              ) : null
          }
          , we would be delighted if you could make our wedding celebration.
          Please fill out your details below so we know how to best accommodate you.
        </p>
        {
          getBooleanValueFromString(acommodationIncluded)
            ? <p>Your accommodation is included.</p>
            : null
        }
        <form onSubmit={submit}>
          {formData.map((currentPerson, currentIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={currentIndex} className="PersonInputs">
              <InputField
                index={currentIndex}
                label="Name"
                field="name"
                placeholder="First and last name of partner or child"
                type="text"
                required
                person={currentPerson}
                updateData={updateData}
                formData={formData}
                lockedForNamedInvitee
              />
              <p className="Bold">Will you be attending the wedding?</p>
              <div className="RadioOptions">
                <InputField
                  index={currentIndex}
                  label="Yes"
                  field="attending"
                  type="radio"
                  required
                  value="Yes"
                  person={currentPerson}
                  updateData={updateData}
                  formData={formData}
                />
                <InputField
                  index={currentIndex}
                  label="No"
                  field="attending"
                  type="radio"
                  required
                  value="No"
                  person={currentPerson}
                  updateData={updateData}
                  formData={formData}
                />
              </div>
              <InputField
                index={currentIndex}
                label="Person is under 12 years old"
                field="isKid"
                type="checkbox"
                person={currentPerson}
                updateData={updateData}
                formData={formData}
                hiddenForNamedInvitee
                attendeeOnly
              />
              <InputField
                index={currentIndex}
                label="Dietary requirements"
                field="dietaryRequirements"
                placeholder="You can leave this blank if none"
                type="text"
                person={currentPerson}
                updateData={updateData}
                formData={formData}
                attendeeOnly
              />
            </div>
          ))}
          {
            !getBooleanValueFromString(acommodationIncluded)
              ? (
                <label id="RequiresTransport" className={!namedInviteeAttending ? 'Hidden' : null} htmlFor="requiresTransport">
                  Will require transport between the venue and
                  {' '}
                  <a
                    href="https://maps.app.goo.gl/9jdK62fiKz2Pq6Av9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Maidenhead station
                  </a>
                  <input
                    type="checkbox"
                    name="requiresTransport"
                    id="requiresTransport"
                    onChange={(e) => {
                      updateData(formData.map((person) => ({
                        ...person,
                        requiresTransport: e.target.checked,
                      })));
                    }}
                  />
                </label>
              ) : null
            }
          {
            reachedMaximumNumberOfInvitees
              ? (
                <p>
                  You have reached the maximum number of people you can add.
                  Please contact us if you need to add more.
                </p>
              )
              : null
          }
          <button
            className={allowedAdditinalPeople !== 'Yes' || !namedInviteeAttending ? 'Hidden' : null}
            type="button"
            onClick={addPerson}
            disabled={reachedMaximumNumberOfInvitees}
          >
            Add a partner or child
          </button>
          <button disabled={hasSubmitted} type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

function InputField({
  index,
  label,
  placeholder,
  field,
  type,
  required,
  value,
  person,
  updateData,
  formData,
  lockedForNamedInvitee,
  hiddenForNamedInvitee,
  hiddenForPlusOnes,
  attendeeOnly,
}) {
  const shouldBeHidden = (person.namedInvitee && hiddenForNamedInvitee)
  || (!person.namedInvitee && hiddenForPlusOnes)
  || (!getBooleanValueFromString(person.attending) && attendeeOnly);
  return (
    <label className={shouldBeHidden ? 'Hidden' : null} htmlFor={`${field}${index}${value}`}>
      {label}
      :
      {' '}
      <input
        onChange={
          (e) => {
            updateData(formData.map((personFromState, i) => {
              if (index === i) {
                return {
                  ...personFromState,
                  [field]: type !== 'checkbox' ? e.target.value : e.target.checked,
                };
              }
              return personFromState;
            }));
          }
        }
        placeholder={placeholder}
        required={required}
        value={(() => {
          if (value) return value;
          if (type === 'text') return person[field];
          return undefined;
        })()}
        checked={(() => {
          if (type === 'radio') return value === person[field];
          if (type === 'checkbox') return person[field];
          return null;
        })()}
        type={type}
        name={`${field}${index}`}
        id={`${field}${index}${value}`}
        readOnly={person.namedInvitee && lockedForNamedInvitee}
      />
    </label>
  );
}

const PersonPropType = PropTypes.shape({
  name: PropTypes.string,
  isKid: PropTypes.bool,
  attending: PropTypes.string,
  order: PropTypes.string,
  dietaryRequirements: PropTypes.string,
  requiresTransport: PropTypes.bool,
  namedInvitee: PropTypes.bool,
});

InputField.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  person: PersonPropType.isRequired,
  updateData: PropTypes.func.isRequired,
  formData: PropTypes.arrayOf(PersonPropType).isRequired,
  lockedForNamedInvitee: PropTypes.bool,
  hiddenForNamedInvitee: PropTypes.bool,
  hiddenForPlusOnes: PropTypes.bool,
  attendeeOnly: PropTypes.bool,
};

InputField.defaultProps = {
  placeholder: null,
  required: null,
  lockedForNamedInvitee: false,
  hiddenForNamedInvitee: false,
  hiddenForPlusOnes: false,
  attendeeOnly: false,
  value: null,
};

export default RSVP;
