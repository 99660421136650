import { useState } from 'react';

import close from './icons/close.png';
import menu from './icons/menu.png';

import './Navigation.scss';

function Navigation() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <nav>
      <ul id="Navigation" className={isCollapsed ? 'Collapsed' : null}>
        <li id="Menu" className="NavIcon">
          <button type="button" onClick={() => setIsCollapsed(false)}><img src={menu} alt="Menu" /></button>
        </li>
        <li className="Collapsable"><a onClick={() => setIsCollapsed(true)} href="/">Home</a></li>
        <li className="Collapsable"><a onClick={() => setIsCollapsed(true)} href="/schedule">Schedule</a></li>
        <li className="Collapsable"><a onClick={() => setIsCollapsed(true)} href="/photo-share">Photo share</a></li>
        <li className="Collapsable"><a onClick={() => setIsCollapsed(true)} href="/wishing-well">Wishing Well</a></li>

        <li id="Close" className="NavIcon Collapsable">
          <button type="button" onClick={() => setIsCollapsed(true)}><img src={close} alt="Close" /></button>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
