import PropTypes from 'prop-types';
import { useState } from 'react';

import './InfoPage.scss';

function WishingWell({ giftGiver }) {
  const [shoudShowNotification, setShoudShowNotification] = useState(false);
  const showNotificaiton = () => {
    if (/Android/i.test(navigator.userAgent)) return;
    setShoudShowNotification(true);
    setTimeout(() => {
      setShoudShowNotification(false);
    }, 1500);
  };

  const monzoLinkBase = 'https://monzo.me/augustingrigorov';
  let monzoLinkPersonalized;
  if (giftGiver) {
    monzoLinkPersonalized = `${monzoLinkBase}?d=Wedding gift by ${giftGiver}`;
  }
  return (
    <>
      <h2 className="Cursive">Our Wishing Well</h2>
      <p>
        The greatest gift to us both is having you share in our
        special day, but if you&#39;re thinking of giving us a gift
        to help us on our way, a contribution towards our honeymoon
        and a new home would really make our day! You can do so
        {' '}
        <a href={monzoLinkPersonalized || monzoLinkBase}>here</a>
        .
        {' '}
        <span className="Section">
          Should you encounter any problems you can
          give us a gift in cash at the venue or using
          the following bank details:
          <span className="SubSection">
            <b>Name:</b>
            {' '}
            <SelfCopyingField text="AUGUSTIN GRIGOROV" value="AUGUSTIN GRIGOROV" showNotificaiton={showNotificaiton} />
            <br />
            <b>Sort code:</b>
            {' '}
            <SelfCopyingField text="04-00-04" value="040004" showNotificaiton={showNotificaiton} />
            <br />
            <b>Account number:</b>
            {' '}
            <SelfCopyingField text="56688490" value="56688490" showNotificaiton={showNotificaiton} />
            <br />
            <b>IBAN:</b>
            {' '}
            <SelfCopyingField text="GB06 MONZ 0400 0456 6884 90" value="GB06MONZ04000456688490" showNotificaiton={showNotificaiton} />
          </span>
        </span>
      </p>
      <img src="/images/wishing-well.webp" alt="wishing well" />
      <CopyNotification shoudShowNotification={shoudShowNotification} />
    </>
  );
}

function SelfCopyingField({ text, value, showNotificaiton }) {
  return (
    <input
      className="CopyField"
      readOnly
      onClick={() => {
        navigator.clipboard.writeText(value);
        showNotificaiton();
      }}
      value={text}
    />
  );
}

function CopyNotification({ shoudShowNotification }) {
  return <div className={`Notification ${!shoudShowNotification ? 'Hidden' : null}`}>Value copied to clipboard</div>;
}

CopyNotification.propTypes = {
  shoudShowNotification: PropTypes.bool.isRequired,
};

SelfCopyingField.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  showNotificaiton: PropTypes.func.isRequired,
};

WishingWell.propTypes = {
  giftGiver: PropTypes.string,
};

WishingWell.defaultProps = {
  giftGiver: null,
};

export default WishingWell;
