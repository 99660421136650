import './ErrorPage.scss';
import './InfoPage.scss';

function PageNotFound() {
  return (
    <div className="InfoPage ErrorMessage">
      <h2>💔 We couldn&#39;t retrieve this invitation</h2>
      <p>
        We are very sorry but we couldnt&#39;t find this invitation.
        Please try refreshing this page and if the problem persists
        please make sure you contact us so we can sort it out.
      </p>
    </div>
  );
}

export default PageNotFound;
