import './ErrorPage.scss';

function ErrorRecordingResponses() {
  return (
    <div className="ErrorMessage InfoPage">
      <h2>💔 There was an error recording your response</h2>
      <p>
        Unfortunately something went wrong while recording your
        response. We are very sorry about that. Please get in
        touch with us directly and we will handle it.
        {' '}
        <span className="Emphasis">
          Please note that until you do so we won&#39;t be able to
          know if you&#39;re coming.
        </span>
      </p>
    </div>
  );
}

export default ErrorRecordingResponses;
