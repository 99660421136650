import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import ImageGallery from 'react-image-gallery';

import './Home.scss';

const galleries = (() => {
  const result = [];
  for (let i = 1; i < 4; i += 1) {
    const gallery = [];
    for (let j = 1; j < 7; j += 1) {
      const imagePath = `${process.env.PUBLIC_URL}/images/our-story/gallery${i}/picture${j}.jpg`;
      const thumbnailPath = `${process.env.PUBLIC_URL}/images/our-story/gallery${i}/thumbnail${j}.jpg`;
      gallery.push({
        fullscreen: imagePath,
        original: thumbnailPath,
      });
    }
    result.push(gallery);
  }
  return result;
})();

function delay(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const getTopImageObject = async (topImageRef, attempts) => {
  if (attempts === 4) return null;
  try {
    const background = new Image();
    // eslint-disable-next-line no-unused-vars
    const [_, backgroundSrc] = window.getComputedStyle(topImageRef.current)
      .getPropertyValue('background-image')
      .match(/url\(["]?(.*)?["]\)/);
    background.src = backgroundSrc;
    return background;
  } catch (e) {
    await delay(100 * attempts);
    return getTopImageObject(topImageRef, attempts + 1);
  }
};

function Hero({ imageLoaded, setLoaded }) {
  const topImageRef = useRef(null);

  useEffect(() => {
    (async () => {
      const topImage = await getTopImageObject(topImageRef, 1);
      if (topImage) {
        topImage.onload = () => {
          setLoaded(true);
        };
      } else {
        Sentry.captureMessage('Could not wait for image to load');
        setLoaded(true);
      }
    })();
  }, []);

  return (
    <>
      <div id="TopImage" ref={topImageRef} />
      <div id="Hero">
        <h2 className={`Names ${imageLoaded ? 'FadeIn' : ''}`}>Estelle & Augustin</h2>
        <div id="Summary" className={imageLoaded ? 'FadeInChildren' : ''}>
          <h3 className="Date">22nd of September 2024</h3>
          <h3>Hedsor House, England</h3>
          <a className="AddToCalendarButton" href={`${process.env.PUBLIC_URL}/wedding-invite.ics`}>Save to My Calendar</a>
        </div>
      </div>
    </>
  );
}

Hero.propTypes = {
  imageLoaded: PropTypes.bool.isRequired,
  setLoaded: PropTypes.func.isRequired,
};

function Home() {
  const [imageLoaded, setLoaded] = useState(false);

  return (
    <div id="Home">
      <Hero imageLoaded={imageLoaded} setLoaded={setLoaded} />
      <div id="OurStory">
        <h2>Our Story</h2>
        <div id="Section1" className="Section">
          <div className="GalleryContainer">
            {
              imageLoaded
                ? (
                  <ImageGallery
                    lazyLoad
                    items={galleries[0]}
                    showThumbnails={false}
                    showBullets
                  />
                ) : null
            }
          </div>
          <p>
            If we had to describe us in a sentence we&#39;d say we&#39;re like wafer and chocolate
            - different in many ways but we make a great pairing. Some key ways in which we are
            alike, however, are - we love spending time together at home, exploring new and
            exciting places, we&#39;re really bad at showing up on time, and we both love Asian
            food.
          </p>
        </div>
        <div id="Section2" className="Section">
          <p>
            After we met &#40;on tinder 🔥&#41; we grew close very quickly and have been
            inseparable ever since. We&#39;ve since done so much together - we&#39;ve been to 3
            continents, we&#39;ve picked up new hobbies, discovered many TV series we like, bought
            a lot of plush toys, survived a pandemic, and fell in love with one another more and
            more each day.
          </p>
          <div className="GalleryContainer">
            {
              imageLoaded
                ? (
                  <ImageGallery
                    lazyLoad
                    items={galleries[1]}
                    showThumbnails={false}
                    showBullets
                  />
                ) : null
            }
          </div>
        </div>
        <div id="Section3" className="Section">
          <div className="GalleryContainer">
            {
              imageLoaded
                ? (
                  <ImageGallery
                    lazyLoad
                    items={galleries[2]}
                    showThumbnails={false}
                    showBullets
                  />
                ) : null
            }
          </div>
          <p>
            Six years later we&#39;re thrilled to make a lifelong commitment to each other in
            the company of our family and closest friends. Getting married is an exciting
            milestone for us where we get to celebrate our love and make you part of our story,
            and we can&#39;t wait for that!
          </p>
        </div>
      </div>
      <div id="Venue">
        <h2>The Venue</h2>
        <div className="GalleryContainer">
          {
            imageLoaded
              ? (
                <ImageGallery
                  lazyLoad
                  items={(() => {
                    const gallery = [];
                    for (let i = 1; i < 7; i += 1) {
                      gallery.push({
                        fullscreen: `${process.env.PUBLIC_URL}/images/venue-gallery/picture${i}.jpg`,
                        original: `${process.env.PUBLIC_URL}/images/venue-gallery/thumbnail${i}.webp`,
                      });
                    }
                    return gallery;
                  })()}
                  showThumbnails={false}
                  showBullets
                />
              ) : null
          }
        </div>
        <p>
          Hedsor House is the kind of place you fall in love with at first sight. It&#39;s
          grand, stunning, dreamy and serene. It&#39;s the perfect setting to make that special
          promise to one another under the spell of the enchanting allure of the Regency era.
        </p>
        <h3>Getting there</h3>
        <h4>Address</h4>
        <address>Hedsor House, Hedsor Park, Taplow, Buckinghamshire, SL6 0HX</address>
        <h4>By Car</h4>
        <p>
          The Hedsor estate is just 30 minutes by car from west London and less than 30 minutes
          from London Heathrow Airport. There are 100 car parking spaces on the estate.
        </p>
        <h4>By Train</h4>
        <p>
          From London Paddington to Taplow or Maidenhead (GWR or Elizabeth Line) - journey time is
          approximately 20-30 minutes. Hedsor House is a short 10min drive from the station. We
          will be providing transport from the station for guests arriving by train.
        </p>
      </div>
      <div id="Gallery">
        <h2>Gallery</h2>
        <div className="GalleryContainer">
          {
            imageLoaded
              ? (
                <ImageGallery
                  lazyLoad
                  items={(() => {
                    const gallery = [];
                    for (let i = 1; i < 12; i += 1) {
                      gallery.push({
                        fullscreen: `${process.env.PUBLIC_URL}/images/main-gallery/picture${i}.jpg`,
                        original: `${process.env.PUBLIC_URL}/images/main-gallery/thumbnail${i}.webp`,
                      });
                    }
                    return gallery;
                  })()}
                  showThumbnails={false}
                  showBullets
                />
              ) : null
          }
        </div>
      </div>
      <h2 id="Hashtag">
        <a href="https://www.instagram.com/explore/tags/AugustelleForever">
          #AugustelleForever
        </a>
      </h2>
    </div>
  );
}

export default Home;
