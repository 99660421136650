import './Loading.scss';

function Loading() {
  return (
    <div id="Loading">
      <img src="/images/logo.webp" alt="loading" />
    </div>
  );
}

export default Loading;
