import PropTypes from 'prop-types';

import WishingWell from './WishingWell';

import './InfoPage.scss';
import './Confirmation.scss';

function Confirmation({ attending }) {
  return (
    <div id="Confirmation" className="InfoPage">
      {
      attending
        ? <Attendence />
        : <NonAttendence />
      }
    </div>
  );
}

function Attendence() {
  const getParams = new URLSearchParams(window.location.search);
  const person1 = getParams.get('person1');
  const person2 = getParams.get('person2');

  let giftGiver = person1;
  if (person2) giftGiver += ` and ${person2}`;

  return (
    <>
      <div className="ConfirmationMessage">
        <h2 className="Cursive">
          We can&#39;t wait to see you!
        </h2>
        <p>
          Your attendance has been confirmed. Thank you
          for making the time to be with us on our big day. ❤️
        </p>
      </div>
      <WishingWell giftGiver={giftGiver} />
    </>
  );
}

function NonAttendence() {
  return (
    <>
      <h2 className="Cursive">
        We&#39;re sorry you can&#39;t make it.
      </h2>
      <p>
        Needless to say we would have loved to see you, but we understand
        that not everyone can make it work. We know that you&#39;ll be there
        in spirit with us and that&#39;s enough. Look out for pictures of the
        event
        {' '}
        <a href="/photo-share">here</a>
        , or on Instagram using the hashtag
        {' '}
        <a href="https://www.instagram.com/explore/tags/AugustelleForever">
          #AugustelleForever
        </a>
        .
      </p>
      <img src="/images/confirmation-not-attending.webp" alt="not attending" />
    </>
  );
}

Confirmation.propTypes = {
  attending: PropTypes.bool.isRequired,
};

export default Confirmation;
