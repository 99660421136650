import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  useParams,
} from 'react-router-dom';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import './PhotoShare.scss';
import 'react-photo-view/dist/react-photo-view.css';

const folderId = '1jkY1qPxRcBftZTy6O4G07PXnvhUy4y3h';
const imagesInFolderUrl = `https://www.googleapis.com/drive/v3/files?q="${folderId}" in parents and mimeType contains "image/"&key=${process.env.REACT_APP_GOOGLE_DRIVE_API_KEY}`;

const mimeToExtension = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/webp': 'webp',
  'image/bmp': 'bmp',
};

async function uploadToGoogleDrive(file, key) {
  const fileMetadata = {
    name: file.name,
    parents: [folderId], // This ensures the file is uploaded to the specific folder
  };

  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
  form.append('file', file);

  const tokenResponse = await fetch(`/.netlify/functions/getDriveUploadAccessToken?key=${key}`);
  if (!tokenResponse.ok) {
    Sentry.captureMessage('Failed to fetch token');
    return;
  }
  const { token } = await tokenResponse.json();

  try {
    const response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: form,
    });

    if (!response.ok) {
      Sentry.captureMessage('Failed to upload file to Google Drive');
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

async function shareImage(src) {
  const response = await fetch(src);
  const imageBlob = await response.blob();

  // Use Web Share API to share the image Blob
  if (navigator.share) {
    try {
      await navigator.share({
        files: [new File([imageBlob], `image.${mimeToExtension[imageBlob.type]}`, { type: imageBlob.type })],
      });
    } catch (error) {
      if (!error.name === 'AbortError') {
        Sentry.captureException(error);
      }
    }
  } else {
    Sentry.captureMessage('Web Share API not supported');
  }
}

function PhotoExchange() {
  const urlKey = useParams().key;
  const localStorageKey = localStorage.getItem('uploadKey');
  const key = urlKey || localStorageKey;

  if (urlKey) {
    localStorage.setItem('uploadKey', urlKey);
  }

  const [imagesInGallery, addImages] = useState([]);
  const [activeUploadRequestIds, setActiveUploadRequestIds] = useState([]);
  const [currentRequestNumber, setCurrentRequestNumber] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(imagesInFolderUrl);

        if (!response.ok) {
          Sentry.captureMessage('Failed to fetch images from Google Drive');
        }

        const { files } = await response.json();
        addImages(files.map(
          ({ id }) => {
            const imageUrl = `/.netlify/images?url=https://drive.usercontent.google.com/download%3Fid=${id}`;
            return ({
              id,
              thumbnailUrl: `${imageUrl}&w=160`,
              imageUrl,
              downloadUrl: `https://drive.google.com/uc?id=${id}&export=download&key=${process.env.REACT_APP_GOOGLE_DRIVE_API_KEY}`,
              srcSet: `${imageUrl}&w=320 2x`,
            });
          },
        ));
      } catch (error) {
        Sentry.captureException(error);
      }
    })();
  }, []);

  const handleAddImage = (event) => {
    const images = Array.from(event.target.files);

    addImages([...images.map((image, index) => {
      const uploadId = `uploadId-${currentRequestNumber + index}`;
      setActiveUploadRequestIds((prevActiveRequestIds) => ([...prevActiveRequestIds, uploadId]));

      (async () => {
        await uploadToGoogleDrive(image, key);
        setActiveUploadRequestIds(
          (prevActiveRequestIds) => prevActiveRequestIds.filter(
            (activeUploadRequestId) => activeUploadRequestId !== uploadId,
          ),
        );
      })();

      // Return image object to be rendered
      return {
        thumbnailUrl: URL.createObjectURL(image),
        imageUrl: URL.createObjectURL(image),
        id: uploadId,
      };
    }), ...imagesInGallery]);

    setCurrentRequestNumber((prevRequestNumber) => prevRequestNumber + images.length);
  };

  return (
    <div id="PhotoShare">
      <h1>Upload and download photos</h1>
      <PhotoProvider
        loadingElement={<LoadingAnimation />}
        // eslint-disable-next-line react/no-unstable-nested-components
        toolbarRender={({ images, index }) => (
          <>
            <a className="Download" href={imagesInGallery[index].downloadUrl} download={`image_${index}`}>
              <svg className="PhotoView-Slider__toolbarIcon" onClick={() => {}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000">
                <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
            {navigator.share ? (
              <svg
                className="PhotoView-Slider__toolbarIcon Share"
                onClick={() => {
                  shareImage(images[index].src);
                }}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fillRule="evenodd" clipRule="evenodd" d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" fill="#ffffff" />
              </svg>
            ) : null}
          </>

        )}
      >
        <div id="PhotoGrid">
          <label id="ImageUploader" htmlFor="UploadImage" className="GridItem">
            <img alt="Upload button" src="/images/add-image.png" />
            <input
              id="UploadImage"
              type="file"
              name="uploadImage"
              accept="image/*"
              multiple="multiple"
              onChange={handleAddImage}
              onClick={(e) => {
                if (!key) {
                  e.preventDefault();
                  // eslint-disable-next-line no-alert
                  alert('To add images first scan the QR code at your table');
                }
              }}
            />
          </label>
          {imagesInGallery.map(({
            id, imageUrl, thumbnailUrl, srcSet,
          }) => (
            <PhotoView key={id} src={imageUrl}>
              <div
                key={id}
                className="GridItem"
              >
                <img
                  referrerPolicy="no-referrer"
                  src={thumbnailUrl}
                  srcSet={srcSet}
                  className={activeUploadRequestIds.some(
                    (activeUploadRequestId) => id === activeUploadRequestId,
                  ) ? 'Loading' : ''}
                  alt=""
                  loading="lazy"
                />
              </div>

            </PhotoView>
          ))}
        </div>
      </PhotoProvider>
    </div>
  );
}

function LoadingAnimation() {
  return (
    <span className="Loader" />
  );
}

export default PhotoExchange;
